import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import { GiLinkedRings, GiWifiRouter } from "react-icons/gi";
import { MdOutlineContentCopy } from "react-icons/md";
import StarlinkImage from "../images/starlink-setup.webp";

const h1ClassNames = "text-center font-bold text-4xl pb-5";
const h2ClassNames = "italic font-bold text-3xl pt-4";
const pClassNames = "pt-4 pl-2 text-xl";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-xl";

export function Head() {
  return (
    <>
      <title>Overlook Chalet</title>
      <meta name="description" content="Overlook Chalet" />
    </>
  );
}

const PrivacySecurity = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <h1 className={h1ClassNames}>Privacy and Security</h1>
          <p className={pClassNames}>
            Your privacy, security and comfort is our highest priority. Rest
            assured that you have the highest degree of privacy while you are
            staying in our home. With that in mind, we want to make sure you're
            aware of any monitoring devices present on the property.
          </p>
          <p className={pClassNames}>
            As always feel free to contact us with any questions.
          </p>

          <h2 id="smoke" className={h2ClassNames}>
            Smoking & Noise Detection
          </h2>
          <p className={pClassNames}>
            We use a device called a Wynd Sentry to monitor for smoke, parties
            and excessive noise. This device is able to monitor for these
            activities without invading your privacy. The Sentry is able to
            distinguish between kitchen, fireplace, and recreational smoke and
            will notify us if any of these are present on the premisis. This is
            not only important to maintain the quality and comfort of our home,
            but also your safety.
          </p>
          <p className={pClassNames}>
            This device also monitors for excessive noise. The Sentry does not
            record or listen to any conversation, so rest assured your privacy
            is maintained. This device detects excessive noise using decibel
            detection, which evaluates the volume of the ambient noise in the
            room.
          </p>
          <p className={pClassNames}>
            To learn more about the Sentry and your privacy,{" "}
            <a href="https://hellowynd.com/pages/zero-incidence">
              please find additional details here.{" "}
              <GiLinkedRings className="m-auto inline" />
            </a>
          </p>
          <h2 id="cameras" className={h2ClassNames}>
            Cameras
          </h2>
          <p className={pClassNames}>
            There are two cameras present on the property. One monitors the
            driveway, and the other monitors the entry door. These cameras do
            not have visibility of the hot tub, or any of the home interior.
          </p>
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default PrivacySecurity;
